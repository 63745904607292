import styled from "styled-components";
import media from "../../constants/media.js";
import { Link } from "react-router-dom";

export const Container = styled.div`
  height: auto;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100vw;
  background: hsla(0, 0%, 100%, 0.8);
  backdrop-filter: saturate(180%) blur(5px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  border: 1px solid #e2e3e2;
  box-shadow: ${(props) => props.theme.shadow};
  z-index: 10000;
`;

export const Brand = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  margin-left: 20px;
  text-decoration: none;
`;

export const Web = styled.div`
  ${media.md`
  display: none;
`}
`;

export const Menu = styled.div`
  height: 40px;
  width: 50px;
  margin-right: 20px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.border};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

export const Mobile = styled.div`
  display: none;

  ${media.md`
  display: inline;
`}
`;

export const Dropdown = styled.div`
  margin-top: 81px;
  height: auto;
  width: 100vw;
  background: hsla(0, 0%, 100%, 0.8);
  backdrop-filter: saturate(180%) blur(5px);
  z-index: 9000;
  border-bottom: 1px solid ${(props) => props.theme.border};
  position: absolute;
  top: 0;
  right: 0;
`;

export const Icon = styled.img`
  height: 24px;
  width: 24px;
`;

export const Logo = styled.img`
  height: 40px;
  width: 40px;
  padding-top: 0px;
`;

export const Routes = styled.div`
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  grid-gap: 20px;

  ${media.md`
    display: none;
  `}
`;

export const MobileRoutes = styled(Link)`
  height: auto;
  padding: 16px;
  width: auto;
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
  color: black;
  font-family: "Gotham", sans-serif;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    background: hsla(0, 0%, 100%, 0.9);
    backdrop-filter: saturate(180%) blur(10px);
  }
`;

export const Route = styled(Link)`
  font-size: 14px;
  font-family: "Gotham", sans-serif;
  color: ${(props) => props.theme.accent};
  text-transform: uppercase;
  text-decoration: none;
`;

export const Company = styled.div`
  font-size: 20px;
  font-family: "Gotham", sans-serif;
  color: ${(props) => props.theme.accent};
  text-transform: uppercase;
  margin-left: -12px;
`;

export const Join = styled(Link)`
  height: 48px;
  width: 140px;
  font-family: "Gotham", sans-serif;
  text-transform: uppercase;
  background: ${(props) => props.theme.accent};
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  border-radius: 4px;
  transition: 750ms;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    background: #000000;
    transition: 750ms;
  }
`;
