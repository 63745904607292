// Relative Imports
import { Container, Membership } from "./styles";

const Alert = () => {
  return (
    <Container>
      <Membership to="/registration">
        Membership registration now available!
      </Membership>
    </Container>
  );
};

export default Alert;
