import styled from "styled-components";
import media from "./media.js";

export const Title = styled.div`
  font-family: "Gotham", sans-serif;
  font-size: 100px;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  max-width: ${(props) => props.maxWidth};
`;

export const SubTitle = styled.div`
  font-size: 80px;
  color: ${(props) => props.theme.primary};
  text-align: center;
  line-height: 1em;
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 20px;

  ${media.md`
    font-size: 60px;
  `}
`;

export const Description = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 18px;
  line-height: 1.5em;
  color: ${(props) => props.theme.secondary};
  max-width: ${(props) => props.maxWidth};
  text-align: ${(props) => props.textAlign};

  ${media.md`
    font-size: 16px;
  `}
`;
export const Footnote = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  color: ${(props) => props.theme.secondary};
  max-width: ${(props) => props.maxWidth};
  text-align: ${(props) => props.textAlign};

  ${media.md`
    font-size: 16px;
  `}
`;

export const H1 = styled.div`
  font-family: "Gotham", sans-serif;
  font-size: 60px;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  margin: none;
  text-transform: uppercase;
`;

export const H2 = styled.div`
  font-family: "Gotham", sans-serif;
  font-size: 50px;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  margin: none;
  text-transform: uppercase;
`;

export const H3 = styled.div`
  font-family: "Gotham", sans-serif;
  font-size: 40px;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  margin: none;
  text-transform: uppercase;
`;

export const H4 = styled.div`
  font-family: "Gotham", sans-serif;
  font-size: 30px;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  margin: none;
  text-transform: uppercase;
`;

export const H5 = styled.div`
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  margin: none;
  text-transform: uppercase;
`;

export const H6 = styled.div`
  font-family: "Gotham", sans-serif;
  font-size: 14px;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  margin: none;
  text-transform: uppercase;
`;

export const P = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: ${(props) => props.theme.secondary};
  line-height: 1.5em;
`;

export const Information = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 13px;
  color: ${(props) => props.theme.secondary};
  line-height: 1.5em;
`;
