import { useEffect } from "react";

import Navigation from "../components/navigation";
import Footer from "../components/footer";
import Question from "../components/question";

const Questions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navigation />
      <Question />
      <Footer />
    </>
  );
};

export default Questions;
