import styled from "styled-components";
import media from "../../constants/media.js";

export const Container = styled.div`
  width: 100vw;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;

  ${media.md`
        margin-top: 0px;
    `};
`;

export const Content = styled.div`
  height: auto;
  width: 100%;
  max-width: 1360px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 30px;
  align-items: center;
  padding: 20px;

  ${media.md`
    width: 100%;
    display: flex;
    flex-direction: column;
  `};
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  justify-content: center;
  height: 100%;
  width: 100%;

  ${media.md`
    width: 100%;
    text-align: left;
  `};
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Inner = styled.div`
  padding: 20px;
  width: 100%;
  height: 360px;

  ${media.md`
    height: 400px;
  `};
`;

export const InnerDetails = styled.div`
  width: 100%;
  height: 360px;
  display: flex;
  grid-gap: 20px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
`;

export const Map = styled.div`
  height: 400px;
  width: 100%;
  border-radius: 8px;
  font-family: "Gotham", sans-serif;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
`;
