import styled from "styled-components";
import { Field } from "formik";

export const Container = styled.div`
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Labels = styled.div`
  display: flex;
  font-family: "Inter", sans-serif;
  flex-direction: row;
  justify-content: space-between;
`;

export const Label = styled.label`
  font-size: 13px;
  margin-bottom: 4px;
  color: ${(props) => props.theme.secondary};
`;

export const Error = styled.label`
  font-size: 13px;
  margin-bottom: 4px;
  color: ${(props) => props.theme.accent};
`;

export const FieldInput = styled(Field)`
  padding: 16px 20px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  border-radius: 4px;
  outline: none;
  color: ${(props) => props.theme.primary};
  border: 1px solid ${(props) => props.theme.border};
  background: ${(props) => props.theme.foreground};
`;

export const Option = styled.option`
  padding: 16px 20px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  border-radius: 4px;
  outline: none;
  color: ${(props) => props.theme.primary};
  border: 1px solid ${(props) => props.theme.border};

  &::placeholder {
    color: ${(props) => props.theme.secondary};
  }
`;
