import { useEffect } from "react";
import Navigation from "../components/navigation";
import Hero from "../components/hero/index.js";
import Video from "../components/video/index.js";
import Pricing from "../components/pricing/index.js";
import ClassPreview from "../components/classPreview/index.js";
import Footer from "../components/footer";
import Modal from "../components/modal/index.js";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navigation />
      <Hero />
      <Video />
      <ClassPreview />
      <Pricing />
      <Footer />
    </>
  );
};

export default Home;
