// Relative Imports
import { Container, Content, Title } from "./styles";
import { Description } from "../../constants/type.js";
const Video = () => {
  return (
    <Container>
      <Title>Our Academy</Title>
      <Description maxWidth={"800px"} textAlign={"center"}>
        Learn more about the history, facility and experience you can expect
        when visting La Academia in San Juan, Puerto Rico.
      </Description>
      <Content>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/7aTPBe1BaDM"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Content>
    </Container>
  );
};

export default Video;
