import styled from "styled-components";
import media from "../../../constants/media.js";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  ${media.sm`
    grid-template-columns: 1fr;
    grid-gap: 0px;
  `};
`;
