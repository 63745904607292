// Relative Imports
import { Container, Content, Title, Card, Portrait, Overlay } from "./styles";
import { Description } from "../../constants/type.js";
import woman from "../../assets/portraits/woman.png";
import kids from "../../assets/portraits/kids.png";
import adult from "../../assets/portraits/adult.png";
import competitor from "../../assets/portraits/competitor.png";

const ClassPreview = () => {
  return (
    <Container>
      <Title>Classes</Title>
      <Description maxWidth={"800px"} textAlign={"center"}>
        We have a wide range of classes for students of all levels and expertise
        in a friendly and welcoming culture.
      </Description>
      <Content>
        <Card to="/classes">
          <Portrait image={kids}>
            <Overlay>Kids Classes</Overlay>
          </Portrait>
        </Card>
        <Card to="/classes">
          <Portrait image={adult}>
            <Overlay>Adults Classes</Overlay>
          </Portrait>
        </Card>
        <Card to="/classes">
          <Portrait image={woman}>
            <Overlay>Womens Classes</Overlay>
          </Portrait>
        </Card>
        <Card to="/classes">
          <Portrait image={competitor}>
            <Overlay>Private Classes</Overlay>
          </Portrait>
        </Card>
      </Content>
    </Container>
  );
};

export default ClassPreview;
