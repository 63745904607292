import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  height: 42px;
  background: #000;
  font-family: "Gotham", sans-serif;
  position: fixed;
  z-index: 3000;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Membership = styled(Link)`
  color: #fff;
  font-size: 13px;
  padding: 0px 12px;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }
`;
