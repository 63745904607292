import { useEffect } from "react";

import Navigation from "../components/navigation";
import Footer from "../components/footer";
import ClassType from "../components/classTypes";

const Classes = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navigation />
      <ClassType />
      <Footer />
    </>
  );
};

export default Classes;
