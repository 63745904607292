import styled from "styled-components";

export const Container = styled.div`
  height: auto;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding: 20px;
`;

export const Content = styled.div`
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 1200px;
  background: #fafafa;
  margin-top: 40px;
`;

export const Title = styled.div`
  font-size: 80px;
  color: ${(props) => props.theme.primary};
  text-align: center;
  line-height: 1em;
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 20px;
`;
