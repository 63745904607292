import { createGlobalStyle } from "styled-components";
import Gotham from "../assets/fonts/Gotham-Bold.otf";

export const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    border: 0;
    background: "#fafafa";
    display: flex;
    flex: 1;
    font-feature-settings: 'zero' 1;
    font-size: 16px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-display: swap;

    @font-face {
      font-family: 'Gotham';
      src: url(${Gotham}) format('opentype'),
    }

`;
