import { useEffect } from "react";

import Navigation from "../components/navigation";
import Form from "../components/registration";
import Footer from "../components/footer";

const Registration = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navigation />
      <Form />
      <Footer />
    </>
  );
};

export default Registration;
