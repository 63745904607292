import { useEffect } from "react";

import Navigation from "../components/navigation";
import Footer from "../components/footer";
import Profressor from "../components/profressor";
import Panel from "../components/panel/index.js";

const Professors = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navigation />
      <Profressor />
      <Panel />
      <Footer />
    </>
  );
};

export default Professors;
