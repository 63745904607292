// Relative Imports
import { Container, Content, Title, Spacer, Inline } from "./styles";
import { Description, SubTitle } from "../../constants/type.js";
import Accordion from "../accordion/index.js";
import { questions } from "../../constants/questionObj.js";

const Question = () => {
  const renderQuestions = () => {
    return questions.map((q, i) => {
      return <Accordion key={i} title={q.title} description={q.description} />;
    });
  };

  return (
    <>
      <Container>
        <SubTitle>FAQ's</SubTitle>
        <Description maxWidth={"800px"} textAlign={"center"}>
          Going to a new gym for the first time can be a intimidating experience
          and we know you have a lot of questions that you'd like answered. You
          can always contact us via email, phone or social if you have more
          questions.
        </Description>
      </Container>
      <Inline> {renderQuestions()}</Inline>
    </>
  );
};

export default Question;
