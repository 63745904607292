import { useEffect } from "react";

import Navigation from "../components/navigation";
import Timetable from "../components/timetable";
import Times from "../components/times";
import Footer from "../components/footer";

const Schedule = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navigation />
      <Times />
      <Footer />
    </>
  );
};

export default Schedule;
