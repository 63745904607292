export const layout = {
  format: ["px", "rem", "em"],
  fontFamily: '"Inter", sans-serif, -apple-system, BlinkMacSystemFont',
  fontSize: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontHeight: [1.0, 1.125, 1.25, 1.375, 1.5],
  fontWeight: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  space: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40],
  media: {
    xxs: 320,
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1140,
    xl: 1920,
    xxl: 2560,
  },
  default: {
    radius: "4px",
    padding: "16px",
  },
};

export const light = {
  name: "light",
  base: "#F7F9FA",
  background: "#fafafa",
  foreground: "#ffffff",
  navigation: "#E0004D",
  border: "#e2e3e2",
  primary: "#2b2d42",
  secondary: "#7f8a9d;",
  input: "#fafafa",
  accent: "#000000",
  shadow: "0px 4px 9px rgba(126, 123, 123, 0.06);",
  layout: layout,
  // variants: variants,
};
