// Relative Imports
import {
  Container,
  Window,
  Row,
  Left,
  Right,
  Column,
  Title,
  Body,
  Cell,
  Icon,
} from "./styles";
import { H5 } from "../../constants/type.js";

const Modal = ({ onClick }) => {
  return (
    <>
      <Container>
        <Window>
          <Column>
            <Title>Connect with Us</Title>
          </Column>
          <Body>
            <Cell href="https://instagram.com/LaAcademiaHQ">
              <Icon />
              Instagram
            </Cell>
            <Cell href="https://instagram.com/LaAcademiaHQ">
              <Icon />
              TikTok
            </Cell>
            <Cell href="https://instagram.com/LaAcademiaHQ">
              <Icon />
              Twitter
            </Cell>

            <Cell href="https://instagram.com/LaAcademiaHQ">
              <Icon />
              Youtube
            </Cell>
            <Cell href="https://instagram.com/LaAcademiaHQ">
              <Icon />
              Medium
            </Cell>
          </Body>
          <Row>
            <Right onClick={onClick}>Close</Right>
          </Row>
        </Window>
      </Container>
    </>
  );
};

export default Modal;
