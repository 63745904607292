// Relative Imports
import { Container, Content, Title, Card, Price, Row, Button } from "./styles";
import { H1, H6, H5, Footnote, Description } from "../../constants/type.js";

const Pricing = () => {
  return (
    <Container>
      <Title>Pricing</Title>
      <Description maxWidth={"800px"} textAlign={"center"}>
        We offer a range of memberships on a month to month basis and there are
        no registration fees. The prices below are our prelaunch prices that
        allow students to lockin rates and will increase on October 1st 2023.
      </Description>
      <Content>
        <Card>
          <Price>
            <H1>$25</H1>
            <H6>Per Visit</H6>
            <H6>Drop In</H6>
          </Price>
          <Row>Single Class Fee</Row>
          <Row>Includes Gi rental</Row>
          <Row>Requires a rashgaurd</Row>
          <Button to="/registration">Register Now</Button>
        </Card>
        <Card>
          <Price>
            <H1>$125</H1>
            <H6>Per Month</H6>
            <H6>Monthly Membership</H6>
          </Price>
          <Row>Unlimited Classses</Row>
          <Row>25% Off Private Classes</Row>
          <Row>25% Annual Membership</Row>
          <Button to="/registration">Register Now</Button>
        </Card>
        <Card>
          <Price>
            <H1>$175</H1>
            <H6>Per Month</H6>
            <H6>Family Membership</H6>
          </Price>
          <Row>Unlimited Classses</Row>
          <Row>25% Annual Membership</Row>
          <Row>$50 Per extra member</Row>
          <Button to="/registration">Register Now</Button>
        </Card>
      </Content>
      <Footnote maxWidth={"800px"} textAlign={"center"}>
        Memberships are month to month. There's no initiation or registration
        fee, and we offer 25% discounts for annual memberships. Prices are
        subject to change.
      </Footnote>
    </Container>
  );
};

export default Pricing;
