// Relative Imports
import { Container, Content, Title } from "./styles";
import { Description, SubTitle } from "../../constants/type.js";

const Profressor = () => {
  return (
    <Container>
      <SubTitle>Instructors</SubTitle>
      <Description maxWidth={"800px"} textAlign={"center"}>
        La Academia's professors are high-level instructors each with over 10
        years experience in martial arts, with a background in profressional and
        competitive experience.
      </Description>
    </Container>
  );
};

export default Profressor;
