//stackoverflow.com/questions/60519123/passing-value-to-state-using-react-select
import Select from "react-select";
// Relative Imports
import { Container, Label, Error, Labels } from "./styles";

const style = {
  // Options
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#2b2d42" : "#7f8a9d",
    height: "52px",
    background: "none",
    borderLeft: "1px solid #e2e3e2;",
    borderRight: "1px solid #e2e3e2;",
    fontFamily: "Inter",
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    // Select Field
    width: "auto",
    height: "52px",
    border: "1px solid #e2e3e2;",
    borderRadius: "4px",
    display: "flex",
    paddingLeft: "8px",
    fontFamily: "Inter",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const Dropdown = ({ label, error, options, defaultValue, onChange }) => {
  return (
    <Container>
      <Labels>
        <Label>{label}</Label>
        <Error>{error}</Error>
      </Labels>
      <Select
        components={{
          IndicatorSeparator: () => null,
        }}
        defaultValue={defaultValue}
        onChange={onChange}
        styles={style}
        options={options}
      />
    </Container>
  );
};

export default Dropdown;
