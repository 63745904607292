import styled from "styled-components";

export const Container = styled.button`
  padding: 16px 20px;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  width: 100%;
  border: none;
  font-family: "Inter", sans-serif;
  text-align: center;
  background: ${(props) => props.theme.accent};
  margin-top: 20px;

  &:hover {
    cursor: pointer;
  }
`;
