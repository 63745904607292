import styled from "styled-components";

export const Container = styled.div`
  width: auto;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.border};
  margin-top: 4px;
  margin-bottom: 4px;
  grid-gap: 4px;
  display: flex;
  flex-direction: column;
  padding: 20px;

  :hover {
    cursor: pointer;
  }
`;
