// Relative Imports
import { Container, InputField, Label } from "./styles";

const Input = ({
  id,
  name,
  as,
  htmlFor,
  value,
  label,
  placeholder,
  onChange,
}) => {
  return (
    <Container>
      <Label htmlFor={htmlFor}>{label}</Label>
      <InputField
        id={id}
        as={as}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </Container>
  );
};

export default Input;
