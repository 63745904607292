import styled from "styled-components";

export const Container = styled.div`
  height: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1360px;
  display: grid;
  grid-gap: 20px;
  margin-top: 30px;
`;

export const Spacer = styled.div`
  height: 80px;
`;

export const Inline = styled.div`
  max-width: 1360px;
  margin: auto;
  padding: 20px;
  display: grid;
`;
