// Relative Imports
import {
  Container,
  Content,
  Title,
  Map,
  Details,
  Inner,
  Information,
  InnerDetails,
} from "./styles";
import { Description, H5, SubTitle } from "../../constants/type.js";

const ContactUs = () => {
  return (
    <Container>
      <SubTitle>Contact Us</SubTitle>
      <Description maxWidth={"800px"} textAlign={"center"}>
        La Academia is located in San Juan, Puerto Rico, in the bustling
        neighbourhood of Miramar. It's located near all main highways, public
        transport and is walkable from all locations in Condado, Miramar and
        Santurce.
      </Description>
      <Content>
        <Map>
          <Inner>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d946.173091137102!2d-66.07788299752552!3d18.45227187703706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1697416400182!5m2!1sen!2sus"
              width="100%"
              height="100%"
              frameborder="0"
              style={{ border: 0, borderRadius: 4 }}
              allowfullscreen="true"
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </Inner>
        </Map>
        <Details>
          <InnerDetails>
            <Information>
              <H5>Phone</H5>
              <Description>+1 787 123 4567</Description>
            </Information>
            <Information>
              <H5>Email</H5>
              <Description>contact@ourwebsitename.com</Description>
            </Information>
            <Information>
              <H5>Address</H5>
              <Description>
                960 Ponce de León Ave., Miramar. San Juan, PR 00907
              </Description>
            </Information>
            <Information>
              <H5>Hours</H5>
              <Description>Monday - Friday: 7:00am - 8:00 pm</Description>
              <Description>Saurday: 9:00am - 1:00 pm</Description>
              <Description>Sunday: 9:00am - 12:00 pm</Description>
            </Information>
          </InnerDetails>
        </Details>
      </Content>
    </Container>
  );
};

export default ContactUs;
