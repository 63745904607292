import { useState, useEffect } from "react";
import { Container, Content } from "./styles";
import { Description, H5, H6, SubTitle } from "../../constants/type.js";
import Input from "../_formik/input/index.js";
import Select from "../_formik/select/index.js";
import Splitter from "../_formik/splitter/index.js";
import Dropdown from "../_forms/dropdown/index.js";
import Button from "../_formik/button/index.js";
import TextArea from "../_forms/text-area/index.js";
import { Formik, Field, Form } from "formik";
import { Footnote } from "../../constants/type.js";

var Airtable = require("airtable");

const curSelection = ["foo"];
const availableSelection = ["foo", "bar", "john"];
const trainingArray = [
  { value: "Default", label: "Select Option" },
  { value: "Yes", label: "Yes, I'm ready today" },
  { value: "No", label: "No, , I'll wait until you open" },
  { value: "Maybe", label: "I don't know, can you explain more?" },
];
const classArray = [
  { value: "Default", label: "Select Option" },
  { value: "adult", label: "BJJ: Adult Plan" },
  { value: "kids", label: "BJJ: Kids Plan" },
  { value: "women", label: "BJJ: Women's Plan" },
  { value: "family", label: "BJJ: Family Plan" },
];

const leadType = [
  { value: "Default", label: "Select Option" },
  { value: "walkin", label: "Walk In" },
  { value: "google", label: "Google" },
  { value: "facebook", label: "Facebook" },
  { value: "advertisement", label: "Advertisement" },
  { value: "websiteBlog", label: "Website or Blog" },
  { value: "existingMember", label: "Existing Member" },
  { value: "staffMember", label: "Staff Member" },
  { value: "other", label: "Other" },
];

const communicationArray = [
  { value: "Default", label: "Select Option" },
  { value: "any", label: "Phone, Text or Email" },
  { value: "email", label: "Email Only" },
  { value: "phone", label: "Phone Only" },
  { value: "text", label: "Text Only" },
];

const RegistrationForm = () => {
  const [show, toggleShow] = useState(true);
  const [postId, setPostId] = useState();
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    emailAddress: "",
    classTypes: "",
    leadType: "",
    preferredCommunication: "",
    remainingQuestions: "",
  });

  const onSubmit = (e) => {
    var Airtable = require("airtable");
    var base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
    }).base(process.env.REACT_APP_AIRTABLE_BASE);
    base("Table").create(
      {
        firstName: "Josh",
        lastName: "Rio Form",
        dateOfBirth: 12232022,
        emailAddress: "josh@la-academia.xyz",
        phoneNumber: "123456789",
        classTypes: "Adult: Gi",
        preferredCommunication: "Email, Phone or Text",
        remainingQuestions: "Lorem ipsum",
      },
      function (err, record) {
        if (err) {
          console.error(err);
          return;
        }
        console.log(record.getId());
      }
    );
  };

  return (
    <Container>
      <SubTitle>Registration</SubTitle>
      <Description maxWidth={"800px"} textAlign={"center"}>
        La Academia is currently accepting registrations that allow members to
        pay nothing today, but lock-in prices. Enter your email and contact
        information to reserve your space.
      </Description>
      <Content>
        <Formik
          initialValues={{
            firstName: form.firstName,
            lastName: "",
            dateOfBirth: "",
            emailAddress: "",
            classTypes: "",
            leadType: "",
            preferredCommunication: "",
            remainingQuestions: "",
          }}
          onSubmit={async (form) => {
            await new Promise((r) => setTimeout(r, 500));
            alert(JSON.stringify(form, null, 2));
          }}
        >
          <Form>
            <Splitter>
              <Input
                label="First Name"
                htmlFor="firstName"
                id="firstName"
                name="firstName"
                placeholder="Jane"
                value={form.firstName || ""}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, firstName: e.target.value };
                  });
                }}
              />
              <Input
                label="Last Name"
                htmlFor="lastName"
                id="lastName"
                name="lastName"
                placeholder="Doe"
                value={form.lastName || ""}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, lastName: e.target.value };
                  });
                }}
              />
            </Splitter>
            <Splitter>
              <Input
                label="Email Address"
                htmlFor="emailAddress"
                id="emailAddress"
                name="emailAddress"
                placeholder="yourname@email.com"
                type="email"
                value={form.emailAddress || ""}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, emailAddress: e.target.value };
                  });
                }}
              />
              <Input
                label="Phone Number"
                htmlFor="phoneNumber"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="786 123 4567"
                type="number"
                value={form.phoneNumber || ""}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, phoneNumber: e.target.value };
                  });
                }}
              />
            </Splitter>
            <Splitter>
              <Input
                label="Age"
                id="dateOfBirth"
                htmlFor="dateOfBirth"
                name="dateOfBirth"
                placeholder="Enter age"
                type="number"
                value={form.dateOfBirth || ""}
                onChange={(e) => {
                  setForm((prevState) => {
                    return {
                      ...prevState,
                      dateOfBirth: e.target.value,
                    };
                  });
                }}
              />
              <Dropdown
                label="Class Type"
                defaultValue={classArray}
                options={classArray}
                onChange={(e) => {
                  setForm((prevState) => {
                    return {
                      ...prevState,
                      classTypes: e.value,
                    };
                  });
                }}
              />
            </Splitter>
            <Splitter>
              <Dropdown
                label="How did you hear about us?"
                defaultValue={leadType}
                options={leadType}
                isMulti
                onChange={(e) => {
                  setForm((prevState) => {
                    return {
                      ...prevState,
                      leadType: e.value,
                    };
                  });
                }}
              />
              <Dropdown
                label="Preferred Communication"
                defaultValue={communicationArray}
                options={communicationArray}
                onChange={(e) => {
                  setForm((prevState) => {
                    return {
                      ...prevState,
                      preferredCommunication: e.value,
                    };
                  });
                }}
              />
            </Splitter>
            <Dropdown
              label="Are you interested in training now?"
              defaultValue={trainingArray}
              options={trainingArray}
              onChange={(e) => {
                setForm((prevState) => {
                  return {
                    ...prevState,
                    preferredCommunication: e.value,
                  };
                });
              }}
            />
            <Input
              label="Questions or comments"
              htmlFor="remainingQuestions"
              id="remainingQuestions"
              as="textarea"
              name="remainingQuestions"
              placeholder="Have any questions you'd like to know?"
              type="number"
              value={form.remainingQuestions || ""}
              onChange={(e) => {
                setForm((prevState) => {
                  return { ...prevState, remainingQuestions: e.target.value };
                });
              }}
            />
            <Footnote>
              La Academia will be opening in October 2023, but we can offer
              classes with our instructors today, at our network of other gyms.
              If you're interested in this, please select "Yes" in the "Are you
              interested in training today" dropdown.
            </Footnote>
            <Button label="Submit" onSubmit={() => onSubmit()} />
          </Form>
        </Formik>
      </Content>
    </Container>
  );
};

export default RegistrationForm;
