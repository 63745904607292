import styled from "styled-components";
import media from "../../constants/media.js";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  margin-top: 120px;
`;

export const Section = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Times = styled.div`
  padding-top: 20px;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 100px 100px;
`;

export const Time = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  color: ${(props) => props.theme.secondary};
  max-width: ${(props) => props.maxWidth};
  text-align: ${(props) => props.textAlign};

  ${media.md`
    font-size: 16px;
  `}
`;
