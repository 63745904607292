import { useEffect } from "react";

import Navigation from "../components/navigation";
import Footer from "../components/footer";
import ContactUs from "../components/contact";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navigation />
      <ContactUs />
      <Footer />
    </>
  );
};

export default Contact;
