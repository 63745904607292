// Relative Imports
import {
  Container,
  Title,
  Description,
  Content,
  Fill,
  Outline,
  Section,
  Warning,
} from "./styles";
import { scrollPage } from "../../utils/utils.js";

const Hero = ({ onClick, message, isLoggedin }) => {
  return (
    <Container>
      <Content>
        <Title>La Academia</Title>
        <Description>
          La Academia is the premiere martials arts academy in San Juan, Puerto
          Rico. We specialize in providing world class techniques to students of
          all ages, backgrounds and expertise.
        </Description>
        <Section>
          <Outline onClick={scrollPage}>Learn More</Outline>
          <Fill to="/schedule">Schedule</Fill>
        </Section>
      </Content>
    </Container>
  );
};

export default Hero;
