// Relative Imports
import { Container, Section, Time, Times } from "./styles";
import { H6 } from "../../constants/type.js";

const Template = () => {
  return (
    <Container>
      <Section>
        <H6>Monday - Thursday</H6>
        <Times>
          <H6>Time</H6>
          <H6>Class</H6>
          <Time>7:00am</Time> <Time>Adult Gi</Time>
          <Time>8:00am</Time> <Time>Adult No Gi</Time>
          <Time>5:00pm</Time> <Time>Kids Gi</Time>
          <Time>6:00am</Time> <Time>Adult Gi</Time>
          <Time>6:00am</Time> <Time>Adult No Gi</Time>
        </Times>
      </Section>
      <Section>
        <H6>Friday</H6>
        <Times>
          <H6>Time</H6>
          <H6>Class</H6>
          <Time>5:30pm</Time> <Time>Open Mat</Time>
        </Times>
      </Section>
      <Section>
        <H6>Sunday</H6>
        <Times>
          <H6>Time</H6>
          <H6>Class</H6>
          <Time>5:30pm</Time> <Time>Open Mat</Time>
        </Times>
      </Section>
    </Container>
  );
};

export default Template;
