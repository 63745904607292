// Imports
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";

// Pages
import Home from "./pages/Home.js";
import Contact from "./pages/Contact.js";
import Schedule from "./pages/Schedule.js";
import Profressors from "./pages/Profressors.js";
import Questions from "./pages/Questions.js";
import Classes from "./pages/Classes.js";
import Blog from "./pages/Blog.js";
import Registration from "./pages/Registration.js";
import Alert from "./components/alert/index.js";

// Theme
import { light } from "./constants/themes.js";

function App() {
  return (
    <ThemeProvider theme={light}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/instructors" element={<Profressors />} />
          <Route path="/questions" element={<Questions />} />
          <Route path="/classes" element={<Classes />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/registration" element={<Registration />} />
        </Routes>
        <Alert />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
