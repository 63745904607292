// Relative Imports
import { useState } from "react";
import {
  Container,
  Grid,
  Copyright,
  Link,
  Section,
  Title,
  Icon,
  Row,
  Social,
  Route,
  Website,
  SocialRow,
} from "./styles";
import { H6 } from "../../constants/type.js";
import twitter from "../../assets/icons/twitter.svg";
import facebook from "../../assets/icons/facebook.svg";
import instagram from "../../assets/icons/instagram.svg";
import whatsapp from "../../assets/icons/whatsapp.svg";
import google from "../../assets/icons/google.svg";
import Modal from "../modal/index.js";

const Footer = () => {
  const [show, showModal] = useState(false);
  return (
    <>
      <Container>
        {show && <Modal onClick={() => showModal(false)} />}
        <Grid>
          <Row>
            <H6 onClick={() => showModal(true)}>© La Academia 2023</H6>
            <Route to="/">
              <H6>Home</H6>
            </Route>
            <Route to="/questions">
              <H6>FAQ's</H6>
            </Route>
            <Route to="/instructors">
              <H6>Instructors</H6>
            </Route>
            <Route to="/schedule">
              <H6>Schedule</H6>
            </Route>
            <Route to="/classes">
              <H6>Classes</H6>
            </Route>
            <Route to="/contact">
              <H6>Contact</H6>
            </Route>
            <Route to="/registration">
              <H6>Registration</H6>
            </Route>
          </Row>
          <SocialRow>
            <Website href="https://twitter.com/LaAcademiaHQ">
              <Social src={twitter} />
            </Website>
            <Website href="https://www.facebook.com/LaAcademiaHQ">
              <Social src={facebook} />
            </Website>
            <Website href="https://instagram.com/LaAcademiaHQ">
              <Social src={instagram} />
            </Website>
            <Website href="https://youtube.com/LaAcademiaHQ">
              <Social src={whatsapp} />
            </Website>
            <Website href="https://tiktok.com/LaAcademiaHQ">
              <Social src={google} />
            </Website>
          </SocialRow>
        </Grid>
      </Container>
    </>
  );
};

export default Footer;
