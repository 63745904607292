// Relative Imports
import { Container, Image, Text, Divider, Portait } from "./styles";
import { H4, H6, Description } from "../../constants/type.js";
import portrait from "../../assets/portraits/portrait-1.jpg";

const Class = () => {
  return (
    <>
      <Container>
        <Image>
          <Portait />
        </Image>
        <Text>
          <H4>Kids Classes</H4>
          <Divider>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.{" "}
              <Divider>
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </Divider>
            </Description>
          </Divider>
        </Text>
      </Container>
      <Container>
        <Text>
          <H4>Womens Classes</H4>
          <Divider>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.{" "}
              <Divider>
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </Divider>
            </Description>
          </Divider>
        </Text>
        <Image>
          <Portait src={""} />
        </Image>
      </Container>
      <Container>
        <Image>
          <Portait src={""} />
        </Image>
        <Text>
          <H4>Competition Classes</H4>
          <Divider>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.{" "}
              <Divider>
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </Divider>
            </Description>
          </Divider>
        </Text>
      </Container>
      <Container>
        <Text>
          <H4>Wrestling Classes</H4>
          <Divider>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.{" "}
              <Divider>
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </Divider>
            </Description>
          </Divider>
        </Text>
        <Image>
          <Portait src={""} />
        </Image>
      </Container>
    </>
  );
};

export default Class;
