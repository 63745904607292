import styled from "styled-components";
import media from "../../constants/media.js";
import { Link } from "react-router-dom";

export const Container = styled.div`
  height: auto;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding: 20px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1360px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  padding: 20px;
  margin-top: 30px;

  ${media.md`
    grid-template-columns: 1fr 1fr;
  `};

  ${media.sm`
    grid-template-columns: 1fr;
  `};
`;

export const Card = styled.div`
  height: auto;
  background: #fff;
  font-family: "Gotham", sans-serif;
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 4px;
`;

export const Row = styled.div`
  padding: 24px;
  font-family: "Inter", sans-serif;
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.border};

  &:last-child {
    border-bottom: none;
  }
`;

export const Price = styled.div`
  height: 200px;
  width: 100%;
  font-size: 64px;
  font-weight: 500;
  font-family: "Gotham", sans-serif;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.border};
`;

export const Title = styled.div`
  font-size: 80px;
  color: ${(props) => props.theme.primary};
  text-align: center;
  line-height: 1em;
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const Description = styled.div`
  font-size: 18px;
  color: ${(props) => props.theme.secondary};
  max-width: 900px;
  line-height: 1.5em;
  font-family: "Inter", sans-serif;
  text-align: center;
`;

export const Button = styled(Link)`
  font-size: 14px;
  background: #000;
  line-height: 1.5em;
  font-family: "Gotham", sans-serif;
  text-align: center;
  text-transform: uppercase;
  padding: 12px 24px;
  margin: 24px;
  border-radius: 4px;
  outline: none;
  border: none;
  color: white;
  text-decoration: none;
  margin-top: 40px;

  &:hover {
    cursor: pointer;
  }
`;
