// Relative Imports
import { Container, Image, Text, Divider, Portait } from "./styles";
import { H4, H6, Description } from "../../constants/type.js";
import portrait from "../../assets/portraits/portrait-1.jpg";

const Panel = () => {
  return (
    <>
      <Container>
        <Image>
          <Portait src={portrait} />
        </Image>
        <Text>
          <H4>Kike Garriga</H4>
          <H6>Head Instructor & Owner</H6>
          <Divider>
            <H6>Black Belt » Lineage » Person</H6>
          </Divider>
          <Divider>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.{" "}
              <Divider>
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </Divider>
            </Description>
          </Divider>
        </Text>
      </Container>
      <Container>
        <Text>
          <H4>Carlos Perez</H4>
          <H6>Instructor & Owner</H6>
          <Divider>
            <H6>Black Belt » Lineage » Person</H6>
          </Divider>
          <Divider>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.{" "}
              <Divider>
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </Divider>
            </Description>
          </Divider>
        </Text>
        <Image>
          <Portait src={portrait} />
        </Image>
      </Container>
      <Container>
        <Image>
          <Portait src={portrait} />
        </Image>
        <Text>
          <H4>Jason Archilla</H4>
          <H6>Head Muay Thai Instructor & Owner</H6>
          <Divider>
            <H6>Blue Belt » Lineage » Person</H6>
          </Divider>
          <Divider>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.{" "}
              <Divider>
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </Divider>
            </Description>
          </Divider>
        </Text>
      </Container>
      <Container>
        <Text>
          <H4>Dan Vallimont</H4>
          <H6>Head Wrestling Instructor</H6>
          <Divider>
            <H6>2x NCAA National Champion</H6>
          </Divider>
          <Divider>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.{" "}
              <Divider>
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </Divider>
            </Description>
          </Divider>
        </Text>
        <Image>
          <Portait src={portrait} />
        </Image>
      </Container>
    </>
  );
};

export default Panel;
