import styled from "styled-components";
import media from "../../constants/media.js";

export const Container = styled.div`
  display: flex;
  max-width: 1360px;
  grid-template-rows: 1fr 1fr;
  margin: auto;
  margin-top: 120px;

  ${media.lg`
    display: flex;
    flex-direction: column;
  `};
`;

export const Image = styled.div`
  height: 400px;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.lg`
    order: 0;
    width: auto;
  `};
`;

export const Text = styled.div`
  height: 400px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.lg`
    order: 1;
    width: auto;
  `};
`;

export const Divider = styled.div`
  margin-top: 10px;
  margin-botom: 10px;
`;

export const Portait = styled.div`
  height: 100%;
  width: 100%;
  background: #fafafa;
`;
