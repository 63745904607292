// Relative Imports
import { useState } from "react";
import {
  Container,
  Brand,
  Icon,
  Logo,
  Background,
  Join,
  Company,
  Route,
  Routes,
  Web,
  Mobile,
  Dropdown,
  Menu,
  MobileRoutes,
} from "./styles";
import raw from "../../assets/brand/raw.svg";
import menu from "../../assets/icons/menu.svg";
import icon from "../../assets/brand/icon.svg";

const Navigation = () => {
  const [display, setDisplay] = useState(false);
  return (
    <>
      <Container>
        <Brand to="/">
          <Logo src={icon} />
          <Company>La Academia</Company>
        </Brand>
        <Web>
          <Routes>
            <Route to="/">Home</Route>
            <Route to="/schedule">Schedule</Route>
            <Route to="/classes">Classes</Route>
            <Route to="/questions">FAQ's</Route>
            <Route to="/blog">Blog</Route>
            <Route to="/contact">Contact</Route>
          </Routes>
        </Web>
        <Web>
          <Join to="/registration">Register</Join>
        </Web>
        <Mobile>
          <Menu onClick={() => setDisplay(!display)}>
            <Icon src={menu} />
          </Menu>
        </Mobile>
        {display && (
          <Dropdown>
            <MobileRoutes to="/">Home</MobileRoutes>
            <MobileRoutes to="/schedule">Schedule</MobileRoutes>
            <MobileRoutes to="/classes">Classes</MobileRoutes>
            <MobileRoutes to="/questions">FAQ's</MobileRoutes>
            <MobileRoutes to="/contact">Contact</MobileRoutes>
            <MobileRoutes to="/blog">Blog</MobileRoutes>
            <MobileRoutes to="/registration">Registration</MobileRoutes>
          </Dropdown>
        )}
      </Container>
    </>
  );
};

export default Navigation;
