// Relative Imports
import { Container, Content, Title } from "./styles";
import { Description, SubTitle } from "../../constants/type.js";
import Class from "../class/index.js";

const ClassType = () => {
  return (
    <Container>
      <SubTitle>Classes</SubTitle>
      <Description maxWidth={"800px"} textAlign={"center"}>
        Our academy provides classes for all types of students to ensure a
        delightful experience for all levels, genders and ages of students.
      </Description>
      <Class />
    </Container>
  );
};

export default ClassType;
