import styled from "styled-components";
import media from "../../constants/media.js";
import { Link } from "react-router-dom";

export const Container = styled.div`
  height: auto;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding: 20px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1360px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  padding: 20px;
  margin-top: 30px;
  overflow: hidden;

  ${media.md`
    grid-template-columns: 1fr 1fr;
  `};

  ${media.sm`
    grid-template-columns: 1fr;
  `};
`;

export const Card = styled(Link)`
  overflow: hidden;
  background: #fafafa;
  font-family: "Gotham", sans-serif;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #000;
  border-radius: 4px;
`;

export const Portrait = styled.div`
  aspect-ratio: 3 / 4;
  width: 100%;
  background-size: 150%;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  background-image: url(${(props) => props.image});
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  display: flex;
  align-items: flex-end;
`;

export const Overlay = styled.div`
  left: 0;
  top: 0;
  color: white;
  height: 100%;
  width: 100%;
  padding: 0.5rem;
  font-size: 20px;
  display: flex;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.1);
  transition: 750ms;
  color: black;

  &:hover {
    background: rgba(0, 0, 0, 0.5);
    transition: 750ms;
    color: white;
  }
`;

export const Title = styled.div`
  font-size: 80px;
  color: ${(props) => props.theme.primary};
  text-align: center;
  line-height: 1em;
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const Description = styled.div`
  font-size: 18px;
  color: ${(props) => props.theme.secondary};
  max-width: 900px;
  line-height: 1.5em;
  font-family: "Inter", sans-serif;
  text-align: center;
`;
