import styled from "styled-components";
import media from "../../constants/media.js";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: saturate(180%) blur(5px);
  color: white;
  z-index: 20000;
  position: fixed;
  display: flex;
  justify-content: center;
  padding-top: 80px;
  top: 0;
  right: 0;
`;

export const Window = styled.div`
  width: 500px;
  max-height: 560px;
  background: white;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px;

  ${media.sm`
    max-height: 700px;
  `};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Icon = styled.div`
  margin-right: 16px;
  height: 48px;
  width: 48px;
  border-radius: 4px;
  background: white;
`;

export const Body = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  overflow: scroll;
  padding-bottom: 20px;
`;

export const Cell = styled.a`
  padding: 20px;
  border-radius: 8px;
  text-transform: uppercase;
  font-family: "Gotham", sans-serif;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${(props) => props.theme.primary};
  border: 1px solid ${(props) => props.theme.border};
  background: ${(props) => props.theme.background};
`;

export const Title = styled.div`
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

export const Description = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: ${(props) => props.theme.secondary};
  line-height: 1.5em;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Left = styled.div`
  width: 140px;
  height: 48px;
  border: 1px solid ${(props) => props.theme.accent};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.accent};
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }
`;

export const Right = styled.button`
  width: 100%;
  height: 48px;
  background: ${(props) => props.theme.accent};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    color: rgba(255, 255, 255, 0.6);
  }
`;

export const Link = styled.a`
  width: 140px;
  height: 48px;
  background: ${(props) => props.theme.accent};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
`;
