// Relative Imports
import { useState } from "react";
import { Container } from "./styles";
import { H6, Description } from "../../constants/type.js";

const Accordion = ({ title, description }) => {
  const [show, toggleShow] = useState(false);
  return (
    <Container onClick={() => toggleShow(!show)}>
      <H6>{title}</H6>
      {show && <Description>{description}</Description>}
    </Container>
  );
};

export default Accordion;
