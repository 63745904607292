import styled from "styled-components";
import media from "../../constants/media.js";

export const Container = styled.div`
  width: 100vw;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
`;

export const Content = styled.div`
  max-width: 740px;
  display: grid;
  margin-top: 30px;
  width: 100%;
`;

export const Table = styled.div`
  height: auto;
  border-radius: 8px;
  display: grid;
  grid-gap: 1px;
  margin: 20px;
  grid-template-columns: repeat(7, 1fr);
  border: 1px solid ${(props) => props.theme.border};

  ${media.lg`
    grid-template-columns: 1fr;
  `}
`;

export const Header = styled.div`
  height: auto;
  padding: 8px 20px;
  border-bottom: 1px solid ${(props) => props.theme.border};
`;

export const Cell = styled.div`
  height: auto;
  padding: 20px;
  border-bottom: 1px solid ${(props) => props.theme.border};

  &:last-child {
    border: none;
  }

  &:hover {
    background: #fafafa;
    cursor: pointer;
  }
`;

export const Column = styled.div`
  border-right: 1px solid ${(props) => props.theme.border};
  grid-gap: 12px;

  &:last-child {
    border: none;
  }

  ${media.lg`
    height auto;
    border-bottom: 1px solid ${(props) => props.theme.border};
    border-right: none;

    &:last-child {
      border: none;
    }
  `}
`;
